import React from 'react';

import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { GetGogeta } from '../sections/common/GetGogeta/GetGogeta';
import { Hero } from '../sections/ProviderPathway/Hero';
import { Schemas } from '../sections/ProviderPathway/Schemas';
import {SEO} from "../components/Seo/Seo";

const ProviderPathway = () => {
	return (
		<MainLayout>
			<ScPage>
				<Hero />
				<GetGogeta isPartnerPage={true} />
				<Schemas />
			</ScPage>
		</MainLayout>
	);
};

export default ProviderPathway;

export const Head = () => (
	<SEO
		title="Join Gogeta’s Provider Network | Low Commission & Monthly Funding"
		description="Bike retailers enjoy low commissions and flexible vouchers with Gogeta. Nurseries receive monthly funding per child. Sign up today for easy, extra revenue."
	/>
)
